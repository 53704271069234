<template>
  <!--vendor/pages/index/newPolicyForm/confirmInfo/confirmInfo.wxml-->
  <div class="confirm_info_wrapper">
    <div class="product_info_box">
      <div class="product_title">“家政无忧”—标准计划</div>
      <div class="product_period">
        <span>起保日期：</span>
        <span>2020-08-10</span>
      </div>
      <div class="product_period">
        <span>保险期间：</span>
        <span>2020-08-10至2021-08.09</span>
      </div>
      <div class="product_case">
        <div>保障方案</div>
        <div class="case_btn">方案一</div>
      </div>
      <div class="modules_title">投保人信息</div>
      <div class="holder_info">
        <div class="holder_name">张三三</div>
        <div class="holder_line">
          <div class="holder_label">统一社会信用代码</div>
          <div class="holder_span">342221198710014069</div>
        </div>
      </div>
      <div class="modules_title">被保人信息</div>
      <div class="insurance_box">
        <div class="insurance_item">
          <div class="insurance_sort">被保人一</div>
          <div class="insurance_top_line">
            <div class="insurance_name">胡先煦</div>
            <div class="insurance_gender">男</div>
          </div>
          <div class="insurance_line">
            <div class="insurance_label">证件号码</div>
            <div class="insurance_span">342221198710014069</div>
          </div>
          <div class="insurance_line">
            <div class="insurance_label">出生日期</div>
            <div class="insurance_span">1987.10.01</div>
          </div>
        </div>
      </div>
      <div class="info_btn_bottom">
        <div class="tiaokuan">
          <span>已确认</span>
          <span class="span_msg">《投保须知及声明》、《条款详情》</span>
        </div>
        <div class="btn_box">
          <div class="btn_left">180元</div>
          <div class="btn_right" @click="confirmPolicy">确认投保</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './ConfirmInfo.less'
export default {
  name: 'ConfirmInfo',
  data() {
    return {

    }
  },
  methods: {
    confirmPolicy() {

    }
  }
}
</script>

<style>
</style>